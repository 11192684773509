<template>
  <div class="contact">
    <div class="bg">
      <div class="content">
        <h1>用心 专业 相互成就</h1>
      </div>
    </div>
    <div class="main">
      <div class="breadcrumb">
        <a href="/">首页</a>
        <span>&gt;</span>
        <span class="current">联系我们</span>
      </div>
      <div class="content">
        <div class="map-container">
          <div id="container"></div>
        </div>
        <div class="contact-info">
          <div class="info-item">
            <img src="@/assets/contact/item1.png" alt="">
          </div>
          <div class="info-item">
            <img src="@/assets/contact/item2.png" alt="">
          </div>
          <div class="info-item">
            <img src="@/assets/contact/item3.png" alt="">
          </div>
        </div>
        <div class="form-container">
          <div class="container-box">
            <div style="font-size: 28px;">让我们联系您</div>
            <p>LET'S CONTACT YOU</p>
          </div>
          <div class="line"></div>
          <div>
            <form @submit.prevent="handleSubmit">
              <div class="form-row">
                <div class="form-group">
                  <label for="name">您的姓名</label>
                  <input type="text" id="name" v-model="formData.name" placeholder="请填写您的姓名">
                </div>
                <div class="form-group">
                  <label for="phone">您的电话</label>
                  <input type="text" id="phone" v-model="formData.phone" placeholder="请填写您的联系方式">
                </div>
              </div>
              <div class="form-row">
                <div class="form-group">
                  <label for="service">所需服务</label>
                  <input type="text" id="service" v-model="formData.service" placeholder="请填写您的所需服务">
                </div>
                <div class="form-group">
                  <label for="email">您的邮箱</label>
                  <input type="email" id="email" v-model="formData.email" placeholder="请填写您的联系方式">
                </div>
              </div>
              <div class="form-group" style="text-align: left;">
                <label for="description">需求描述</label>
                <textarea id="description" v-model="formData.description" rows="4" placeholder="请填写您的需求详情"></textarea>
              </div>
              <button type="submit">提交信息</button>
            </form>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script setup>
import { ref, nextTick, onMounted } from 'vue';
import Footer from "@/components/Footer";

const formData = ref({
  name: '',
  phone: '',
  service: '',
  email: '',
  description: ''
});

const handleSubmit = () => {
  console.log('提交的数据:', formData.value);
  alert('提交成功！');
  // 清空表单数据
  formData.value = {
    name: '',
    phone: '',
    service: '',
    email: '',
    description: ''
  };
};

onMounted(() => {
  nextTick(() => {
    initMap();
  });
});

const initMap = () => {
  var map = new BMapGL.Map('container');
  var point = new BMapGL.Point(113.934145,22.556637);
  map.centerAndZoom(point, 16);
  // 创建点标记
  var marker = new BMapGL.Marker(point);
  map.addOverlay(marker);
  // 创建信息窗口
  var opts = {
    width: 200,
    height: 100,
    title: '公司地址'
  };
  var infoWindow = new BMapGL.InfoWindow('地址：深圳市南山区艺园路139号唐商科技大厦21层', opts);
  // 点标记添加点击事件
  marker.addEventListener('click', function () {
    map.openInfoWindow(infoWindow, point); // 开启信息窗口
  });
};
</script>

<style scoped lang="scss">
.contact {
  width: 100%;

  .bg {
    width: 100%;
    height: 630px;
    background-image: url("@/assets/contact/bg.png");
    background-size: cover;
    background-position: center;
    display: flex;
    padding-left: 250px;
    box-sizing: border-box;
    align-items: center;

    .content {
      text-align: left;
      color: #000;

      h1 {
        font-size: 48px;
        font-weight: bold;
        margin-bottom: 20px;
      }

      p {
        font-size: 18px;
        margin-bottom: 40px;
      }

      .learn-more {
        background-color: #c71b24;
        color: #fff;
        border: none;
        padding: 10px 20px;
        font-size: 16px;
        cursor: pointer;
        border-radius: 30px;
      }
    }
  }

  .main {
    background-color: #f4f5f7;
    padding: 0 250px;
    margin-bottom: 70px;

    .breadcrumb {
      display: flex;
      align-items: center;
      font-size: 14px;
      color: #636262;
      height: 160px;
      padding-top: 50px;
      box-sizing: border-box;

      a {
        text-decoration: none;
        color: #636262;
      }

      span {
        margin: 0 15px;
      }

      .current {
        padding: 0;
        margin: 0;
      }
    }

    .content {
      padding: 36px 120px;
      background-color: #fff;

      .map-container {
        width: 100%;
        height: 400px;
        margin-bottom: 30px;

        #container {
          width: 100%;
          height: 100%;
        }
      }

      .contact-info {
        width: 100%;
        display: flex;
        justify-content: space-between;

        .info-item {
          background: #fff;
          flex: 1;
          margin-right: 20px;

          &:last-child {
            margin-right: 0;
          }

          img {
            width: 100%;
            height: auto;
            display: block;
          }

          h3 {
            margin-bottom: 10px;
            font-size: 18px;
            color: #333;
          }

          p {
            font-size: 16px;
            color: #666;
          }
        }
      }

      .form-container {
        position: relative;
        margin-top: 130px;
        text-align: center;
        padding: 0 120px;
        padding-top: 120px;

        .container-box {
          background-color: #fff;
          display: block;
          width: 230px;
          height: 80px;
          margin: auto;
          position: absolute;
          left: calc(50% - 112px);
          top: 0;
          z-index: 999;
        }

        .line {
          width: 100%;
          height: 1px;
          background-color: #f2f2f2;
          position: absolute;
          top: 36px;
          z-index: 1;
          margin: auto;
          margin: 0 -120px;
        }

        h2 {
          font-size: 24px;
          font-weight: bold;
          margin-bottom: 10px;
        }

        p {
          font-size: 14px;
          color: #999;
          margin-bottom: 50px;
        }

        form {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;

          .form-row {
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin-bottom: 40px;

            .form-group {
              flex: 1;

              &:nth-child(odd) {
                margin-right: 80px;
              }

              #name,
              #phone,
              #service,
              #email {
                width: 100%;
                border: 0;
                height: 50px;
                background-color: #f8f7f7;
                border-radius: 30px;
                box-sizing: border-box;
                margin-right: 80px;
                padding: 0 20px;
              }

              #description {
                border: 0;
                height: 50px;
                background-color: #f8f7f7;
                border-radius: 30px;
                box-sizing: border-box;
                width: 100%;
                padding: 10px;
                font-size: 14px;
                color: #666;
                resize: none;
              }

              #description::placeholder {
                color: #ccc;
              }

              &:last-child {
                margin-right: 0;
              }

              label {
                display: block;
                font-size: 14px;
                color: #333;
                margin-bottom: 8px;
                text-align: left;
              }

              input::placeholder,
              textarea::placeholder {
                color: #ccc;
                padding: 0 4px;
              }
            }
          }

          .form-group {
            width: 100%;
            margin-bottom: 20px;

            label {
              display: block;
              font-size: 14px;
              color: #333;
              margin-bottom: 8px;
            }

            textarea {
              border: 0;
              height: 160px;
              background-color: #f8f7f7;
              border-radius: 10px;
              box-sizing: border-box;
              width: 100%;
              padding: 10px;
              font-size: 14px;
              color: #666;
              resize: none;
            }

            textarea::placeholder {
              color: #ccc;
            }
          }

          button {
            width: 200px;
            height: 54px;
            box-sizing: border-box;
            background-color: #c71b24;
            color: #fff;
            border: none;
            padding: 10px 20px;
            font-size: 16px;
            cursor: pointer;
            border-radius: 30px;
            transition: background-color 0.3s;

            &:hover {
              background-color: #a3141d;
            }
          }
        }
      }
    }
  }

  @media (max-width: 1440px) {
    .bg {
      padding-left: 150px;

      .content {
        h1 {
          font-size: 40px;
        }

        p {
          font-size: 16px;
        }
      }
    }

    .main {
      padding: 0 150px;

      .content {
        padding: 36px 100px;

        .form-container {
          padding: 0 100px;
          padding-top: 100px;

          .line {
            margin: 0 -100px;
          }
        }
      }
    }
  }

  @media (max-width: 1280px) {
    .bg {
      padding-left: 100px;

      .content {
        h1 {
          font-size: 36px;
        }

        p {
          font-size: 14px;
        }
      }
    }

    .main {
      padding: 0 100px;

      .content {
        padding: 36px 80px;

        .form-container {
          padding: 0 80px;
          padding-top: 80px;

          .line {
            margin: 0 -80px;
          }
        }
      }
    }
  }

  @media (max-width: 1080px) {
    .bg {
      padding-left: 50px;

      .content {
        h1 {
          font-size: 32px;
        }

        p {
          font-size: 12px;
        }
      }
    }

    .main {
      padding: 0 50px;

      .content {
        padding: 36px 60px;

        .form-container {
          padding: 0 60px;
          padding-top: 60px;

          .line {
            margin: 0 -60px;
          }

          .form-row {
            flex-direction: column;

            .form-group {
              margin-right: 0;
              margin-bottom: 20px;
            }
          }
        }
      }
    }
  }
}

</style>